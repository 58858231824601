import React, { FC } from 'react';

//Externals
import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import { useFirebaseApp, useFirestoreDocDataOnce } from 'reactfire';
import { useNavigate } from 'react-router-dom';

//Models
import { UserDTO } from 'app/models/user.dto';
import { PostDetailsDTO } from 'app/models/postDetailsDTO.dto';

const useStyles = makeStyles((theme) => ({
  root: {},
  chip: {
    padding: theme.spacing(0, 1),
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 112
  },
  iconButton: {
    width: 42,
    height: 42
  },
  row: {
    cursor: 'pointer'
  },
  tooltip: {
    background: theme.palette.primary.main
  },
  ellipsis: {
    maxWidth: 800, // percentage also works
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

interface Props {
  postDetail: PostDetailsDTO;
}
const TableRowInfos: FC<Props> = ({ postDetail }) => {
  const classes = useStyles();
  const firebaseApp = useFirebaseApp();
  const firestore = firebaseApp.firestore();
  const authorRef = firestore.collection('users').doc(postDetail.authorId);
  const authorDoc = useFirestoreDocDataOnce<UserDTO>(authorRef);

  const navigate = useNavigate();

  return authorDoc.hasEmitted ? (
    <TableRow
      hover
      className={classes.row}
      key={postDetail.id}
      onClick={() => navigate(`/app/report/${postDetail.id}`, { state: { author: authorDoc.data } })}
      // selected={_.indexOf(selectedStationIds, station.id) !== -1}
    >
      <>
        <TableCell style={{ whiteSpace: 'nowrap' }}>
          {authorDoc.data.firstName + ' ' + authorDoc.data.lastName}
        </TableCell>
        <TableCell className={classes.ellipsis}>{postDetail?.content}</TableCell>
        <TableCell>{postDetail?.reports}</TableCell>
      </>
    </TableRow>
  ) : (
    <div></div>
  );
};

export default TableRowInfos;
