import { FC, useEffect } from 'react';

// Components
import Content from './content';
import Shimmer from './shimmer';

// Externals
import { useUser } from 'reactfire';
import { useLocation } from 'react-router-dom';

// Material
import { Drawer, Hidden, makeStyles } from '@material-ui/core';

// Externals Icons
import { BarChart2 as BarChartIcon, Settings as SettingsIcon, Clipboard as ClipboardIcon } from 'react-feather';

// Styles
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  }
}));

const items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: `Tableau de bord`
  },
  {
    href: '/app/reports',
    icon: ClipboardIcon,
    title: `Signalements`
  },
  {
    href: '/app/settings',
    icon: SettingsIcon,
    title: `Paramètres`
  }
];

interface Props {
  onMobileClose: () => void;
  openMobile: boolean;
}

const NavBar: FC<Props> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();

  const location = useLocation();

  const { status, data, hasEmitted } = useUser();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {status === 'loading' || !hasEmitted ? <Shimmer items={items} /> : <Content data={data} items={items} />}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {status === 'loading' || !hasEmitted ? <Shimmer items={items} /> : <Content data={data} items={items} />}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
