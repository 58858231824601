import React, { FC } from 'react';

//Externals
import clsx from 'clsx';
import _ from 'lodash';
import {
  Avatar,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Collapse,
  makeStyles,
  Typography,
  IconButton,
  List
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';

//Models
import { PostDTO } from 'app/models/post.dto';
import { UserDTO } from 'app/models/user.dto';
import { CommentDTO } from 'app/models/comment.dto';

//Components
import CommentsContent from '../comments';

//Styles
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: 230
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    // backgroundColor: red[500]
  }
}));

interface Props {
  post: PostDTO;
  author: UserDTO;
  comments: CommentDTO[];
}

const PostContent: FC<Props> = ({ post, author, comments }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  if (author.birthDate) {
    const t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(author.birthDate.seconds);
    // console.log(t.toJSON());
    // console.log(new Date(author.birthDate.seconds).toDateString());
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={<Avatar aria-label="recipe" src={author.photoUrl} className={classes.avatar} />}
        title={author.firstName + ' ' + author.lastName}
        subheader={
          author.birthDate?.seconds && moment().diff(new Date(author.birthDate?.seconds).toJSON(), 'years') + ' ans'
        }
      />

      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {post.content}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent style={{ maxHeight: 400, overflow: 'auto' }}>
          <List style={{ overflow: 'auto' }}>
            {_.map(
              _.sortBy(comments, (c) => c.createdAt),
              (comment) => (
                <CommentsContent comment={comment} />
              )
            )}
          </List>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default PostContent;
