import { forwardRef } from 'react';

// Externals
import { Helmet } from 'react-helmet';

interface Props {
  children: React.ReactElement;
  className?: string;
  title: string;
}

const Page = forwardRef<HTMLDivElement, Props>(({ children, className, title }, ref) => {
  return (
    <div className={className} ref={ref}>
      <Helmet>{title ? <title>Kiligi - {title}</title> : <title>Kiligi</title>}</Helmet>
      {children}
    </div>
  );
});

export default Page;
