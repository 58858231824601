// Material
import { createMuiTheme } from '@material-ui/core';

// Shadows
import shadows from './shadows';

// Typography
import typography from './typography';

const MuiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#303133'
    },
    secondary: {
      main: '#FD5C63'
    }
  },
  shadows,
  typography
});

export default MuiTheme;
