//Externals
import { Grid, makeStyles } from '@material-ui/core';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useFirebaseApp, useFirestoreCollectionData, useFirestoreDocDataOnce } from 'reactfire';

//Models
import { PostDTO } from 'app/models/post.dto';
import { UserDTO } from 'app/models/user.dto';
import { CommentDTO } from 'app/models/comment.dto';

//Components
import PostContent from './post';
import Actions from './actions';

//Styles
const useStyles = makeStyles({
  root: {
    padding: 20,
    // justifyContent: 'space-evenly',
    height: '100%'
  },
  content: {
    borderWidth: 2,
    borderColor: 'black'
  },
  wrapper: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'auto'
  }
});

interface Props {
  author: UserDTO;
}

const ReportDetail = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const classes = useStyles();

  const firebaseApp = useFirebaseApp();
  const firestore = firebaseApp.firestore();

  const postRef = firestore.collection('posts').doc(id);
  const postDoc = useFirestoreDocDataOnce<PostDTO>(postRef);
  const { author } = state as Props;

  const commentsRef = postRef.collection('comments');
  const commentsDoc = useFirestoreCollectionData<CommentDTO>(commentsRef);

  const approuvePost = async () => {
    const updatedPost: PostDTO = {
      ...postDoc.data,
      reports: 0
    };
    await firestore.collection('posts').doc(id).set(updatedPost, { merge: true });
    navigate('/app/reports');
  };

  const deletePost = async () => {
    await firestore.collection('posts').doc(id).delete();
    navigate('/app/reports');
  };

  return (
    <Grid container spacing={3} justify="center" className={classes.root}>
      <Grid item sm={4}>
        {/* <Actions approuve={approuve} /> */}
      </Grid>
      <Grid item sm={4}>
        {(postDoc.status === 'loading' || !postDoc.hasEmitted) && !commentsDoc.hasEmitted ? (
          // <PostShimmer />
          <div></div>
        ) : (
          <PostContent author={author} comments={commentsDoc.data} post={postDoc.data} />
        )}
      </Grid>
      <Grid item sm={4}>
        <Actions approuvePost={approuvePost} deletePost={deletePost} />
      </Grid>
    </Grid>
  );
};

export default ReportDetail;
