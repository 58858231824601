import { FC, StrictMode, useEffect, useState } from 'react';

// Context
import i18nContext from 'app/i18n';

// Externals
import { useUser } from 'reactfire';
import { useRoutes } from 'react-router-dom';

// FormatJS
import { IntlProvider } from 'react-intl';

// Messages
import en_GB from 'app/i18n/en-GB.json';
import fr_FR from 'app/i18n/fr-FR.json';

// Routes
import routes from 'app/routes';

declare global {
  interface Window {
    onReactReady: () => void;
  }
}

const App: FC = () => {
  const { status, data, hasEmitted } = useUser();

  const routing = useRoutes(routes(Boolean(data)));

  const [language, setLanguage] = useState('fr-FR');
  const [messages, setMessages] = useState(fr_FR);

  useEffect(() => {
    // *** BUG *** hasEmitted is always false
    // Waiting for the next version
    if (
      status !== 'loading'
      // && hasEmitted
    ) {
      window.onReactReady();
    }
  }, [status, hasEmitted]);

  useEffect(() => {
    switch (language) {
      case 'en-GB':
        setMessages(en_GB);
        break;
      case 'fr-FR':
        setMessages(fr_FR);
        break;
    }
  }, [language]);

  return (
    <i18nContext.Provider value={{ language, setLanguage }}>
      <IntlProvider messages={messages} locale={language} key={language}>
        <StrictMode>{routing}</StrictMode>
      </IntlProvider>
    </i18nContext.Provider>
  );
};

export default App;
