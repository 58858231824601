import React, { FC } from 'react';

//Externals
import _ from 'lodash';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

//Models
import TableRowInfos from '../table_row_infos';
import { PostDetailsDTO } from 'app/models/postDetailsDTO.dto';

interface Props {
  className?: string;
  // onEditModal: (id: string, name: string) => void;
  // onMeasurementWindow: (id: string, name: string) => void;
  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  order: any;
  setOrder: React.Dispatch<React.SetStateAction<any>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  // selectedStationIds: string[];
  // setSelectedStationIds: React.Dispatch<React.SetStateAction<string[]>>;
  postDetails: PostDetailsDTO[];
}
const Content: FC<Props> = ({
  className,
  // onEditModal,
  // onMeasurementWindow,
  limit,
  setLimit,
  order,
  setOrder,
  page,
  setPage,
  // selectedStationIds,
  // setSelectedStationIds
  postDetails
}) => {
  const isSorted = (key: string) => order.hasOwnProperty(key);

  const handleSort = (key: string) => () => {
    if (isSorted(key)) {
      if (order[key] === 'asc') setOrder({ [key]: 'desc' });
      else setOrder({});
    } else {
      setOrder({ [key]: 'asc' });
    }
  };

  const handleLimitChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setLimit(+event.target.value);
  };

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    // console.log(page);
    setPage(page);
  };

  const getSortDirection = (key: string) => order[key];
  // console.log(postDetails);
  return (
    <Card>
      <PerfectScrollbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Auteur du post</TableCell>
              <TableCell>Extrait</TableCell>
              <TableCell>
                <TableSortLabel
                  active={isSorted('reports')}
                  direction={getSortDirection('reports')}
                  onClick={handleSort('reports')}
                >
                  Nombre de signalements
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(postDetails, (postDetail, key) => (
              //  console.log(report)
              <TableRowInfos key={postDetail.id} postDetail={postDetail} />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={postDetails.length}
        labelDisplayedRows={({ from, to, count }) => {
          return from + '-' + to + ' sur ' + count;
        }}
        labelRowsPerPage="Élements par page:"
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

export default Content;
