import { FC } from 'react';

// Common
import Page from 'app/common/page';

// Material
import { Container, makeStyles } from '@material-ui/core';

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Settings: FC = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Paramètres">
      <Container maxWidth={false}>
        <></>
      </Container>
    </Page>
  );
};

export default Settings;
