import React, { FC } from 'react';

//Externals
import { Card, CardContent, CardHeader, makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
    height: 230
  },
  content: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  delete_button: {
    width: '100%',
    marginInline: 20,
    backgroundColor: '#b71c1c',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#801313',
      color: '#fde4d9'
    }
  },
  accept_button: {
    width: '100%',
    marginInline: 20,
    backgroundColor: '#8bc34a',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#618833',
      color: '#fde4d9'
    }
  }
}));

interface Props {
  approuvePost: () => Promise<void>;
  deletePost: () => Promise<void>;
}

const Actions: FC<Props> = ({ approuvePost, deletePost }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader
        title={'Que souhaitez vous faire ?'}
        // subheader={moment().diff(author?.birthDate?.toDate(), 'years')}
      />

      <CardContent className={classes.content}>
        <Button variant="contained" onClick={deletePost} className={classes.delete_button}>
          Supprimer
        </Button>
        <Button variant="contained" onClick={approuvePost} className={classes.accept_button}>
          Conforme
        </Button>
      </CardContent>
    </Card>
  );
};

export default Actions;
