// Externals
import { Navigate } from 'react-router-dom';

// Layout
import DashboardLayout from 'app/layout/dashboard_layout';
import EmptyLayout from 'app/layout/empty_layout';
import MainLayout from 'app/layout/main_layout';

// Views
import Dashboard from 'app/views/dashboard';
import Login from 'app/views/login';
import NotFound from 'app/views/not_found';
import Settings from 'app/views/settings';
import Reports from 'app/views/reports';
import ReportDetail from './views/reports/details';

const anonymous = (isLoggedIn: boolean, component: any) => (!isLoggedIn ? component : <Navigate to="/app/dashboard" />);

const authorize = (isLoggedIn: boolean, component: any) => (isLoggedIn ? component : <Navigate to="/login" />);

const routes = (isLoggedIn: boolean) => [
  {
    path: '/app',
    element: <DashboardLayout />,
    children: [
      { path: 'dashboard', element: authorize(isLoggedIn, <Dashboard />) },
      { path: 'settings', element: authorize(isLoggedIn, <Settings />) },
      { path: 'reports', element: authorize(isLoggedIn, <Reports />) },
      { path: 'report/:id', element: authorize(isLoggedIn, <ReportDetail />) },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/empty',
    element: <EmptyLayout />,
    children: []
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: anonymous(isLoggedIn, <Login />) },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
