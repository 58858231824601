import { FC, useEffect, useMemo, useState } from 'react';

//Externals
import _ from 'lodash';
import { useFirebaseApp } from 'reactfire';
import { Container, makeStyles } from '@material-ui/core';

//Components
import Content from './table/content';
import Page from 'app/common/page';

//Models
import { PostDTO } from 'app/models/post.dto';
import { PostDetailsDTO } from 'app/models/postDetailsDTO.dto';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Reports: FC = () => {
  const firebaseApp = useFirebaseApp();
  const firestore = firebaseApp.firestore();
  const classes = useStyles();

  const [details, setDetails] = useState<PostDetailsDTO[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const [order, setOrder] = useState<any>({ name: 'asc' });
  const [page, setPage] = useState<number>(0);

  const fetchReports = async () => {
    const result = await firestore.collectionGroup('reports').get();
    if (!result.empty) {
      let newDetails: PostDetailsDTO[] = [];

      let promises: Promise<void>[] = [];
      _.each(result.docs, (doc) => {
        //TODO : path & parent id object

        promises.push(
          new Promise(async (resolve, reject) => {
            const parent = doc.ref.parent;
            if (!parent) return;
            const post = (await parent.parent?.get())?.data() as PostDTO;
            if (post) {
              const postDetail: PostDetailsDTO = {
                id: post.id,
                createdAt: post.createdAt,
                modifiedAt: post.modifiedAt,
                authorId: post.authorId,
                being: post.being,
                comments: post.comments,
                content: post.content,
                reports: post.reports,
                feeling: post.feeling,
                needing: post.needing,
                trustLevel: post.trustLevel
              };
              if (post.reports >= 1) {
                newDetails.push(postDetail);
              }
            }

            resolve();
          })
        );
      });

      await Promise.all(promises);

      setDetails(_.uniqBy(newDetails, 'id'));
    }
  };

  const filteredPostDetails = useMemo(
    () => _.orderBy(details, Object.keys(order), Object.values(order)),
    [details, order]
  );

  useEffect(() => {
    fetchReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page className={classes.root} title="Signalements">
      <Container maxWidth={false}>
        <Content
          limit={limit}
          order={order}
          page={page}
          postDetails={filteredPostDetails}
          setLimit={setLimit}
          setPage={setPage}
          setOrder={setOrder}
        />
      </Container>
    </Page>
  );
};

export default Reports;
