import React, { FC } from 'react';

//Externals
import { Avatar, makeStyles, Typography } from '@material-ui/core';
import { useFirebaseApp, useFirestoreDocDataOnce } from 'reactfire';

//Models
import { CommentDTO } from 'app/models/comment.dto';
import { UserDTO } from 'app/models/user.dto';

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    borderColor: '#000000',
    marginBottom: 5,
    border: 10,
    justifyContent: 'flex-start'
  },
  avatar: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 'auto',
    display: 'flex',
    flexDirection: 'column',
    marginRight: 8
  },
  content: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  message: {
    width: '100%',
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderWidth: 1,
    borderRadius: 5,
    marginTop: 4
  },
  links: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  reverseLinks: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  link: {
    fontSize: 14,
    fontWeight: 'bold'
  }
}));

interface Props {
  comment: CommentDTO;
}

const CommentsContent: FC<Props> = ({ comment }) => {
  const firebaseApp = useFirebaseApp();
  const firestore = firebaseApp.firestore();
  const authorRef = firestore.collection('users').doc(comment.authorId);
  const authorDoc = useFirestoreDocDataOnce<UserDTO>(authorRef);
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.avatar}>
        {authorDoc.status === 'loading' || !authorDoc.hasEmitted ? (
          // <SkeletonContent
          //   containerStyle={null}
          //   isLoading={true}
          //   layout={[{ height: 40, width: 40, borderRadius: 20 }]}
          // />
          <div></div>
        ) : (
          // <UserBadge user={authorDoc.data} size={40} />
          <Avatar aria-label="recipe" src={authorDoc.data.photoUrl} className={classes.avatar} />
        )}
      </div>
      <div className={classes.content}>
        {authorDoc.status === 'loading' || !authorDoc.hasEmitted ? (
          // <SkeletonContent
          //   containerStyle={null}
          //   isLoading={true}
          //   layout={[{ height: 16, width: 140, marginTop: 4, alignSelf: isMineComment ? 'flex-end' : 'flex-start' }]}
          // />
          <div></div>
        ) : (
          <p style={{ textAlign: 'left' }}>{authorDoc.data.firstName + ' ' + authorDoc.data.lastName}</p>
        )}
        <div
          className={
            classes.message
            // { borderColor: messageColor, marginTop: 4 },
            // isMineComment ? { borderTopRightRadius: 0 } : { borderTopLeftRadius: 0 }
          }
        >
          <Typography>{comment.content}</Typography>
        </div>
      </div>
    </div>
  );
};

export default CommentsContent;
