import { FC } from 'react';

// Components
import Item from './item';

// Externals
import firebase from 'firebase';
import _ from 'lodash';

// Material
import { Avatar, Box, Divider, Link, List, Typography, makeStyles } from '@material-ui/core';

// Styles
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    width: 80,
    height: 80,
    marginBottom: theme.spacing(1)
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: theme.spacing(1)
  },
  title: {
    width: '100%',
    marginRight: 'auto'
  }
}));

interface Props {
  data: firebase.User;
  items: {
    href: string;
    icon: any;
    title: string;
  }[];
}

const Content: FC<Props> = ({ data, items }) => {
  const classes = useStyles();

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar className={classes.avatar} src="/static/images/avatars/placeholder.png" />
        <Typography color="textPrimary" variant="h5">
          {data?.email}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          Administrateur
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {_.map(items, (item) => (
            <Item href={item.href} key={item.title} title={item.title} icon={item.icon} />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
      <Box display="flex" alignItems="center" justifyContent="center" p={2} m={2}>
        <Typography align="center" variant="caption">
          Made with <strong>{'</>'}</strong> by{' '}
          <Link href="https://www.sparkle.tech/" target="_blank">
            Sparkle
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Content;
